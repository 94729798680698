
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 4, August 2021
   *
   * Summary: Users Dashboard
   */
  import { Component, Vue } from 'vue-property-decorator';
  import { Action, namespace } from 'vuex-class';
  import {
    getErrorMessage,
    supportedLanguages,
    capitalizeSingleWord
  } from '@/utils/Helpers';
  import ASCXUsersList from '@rdss-mono/ascx-users-list';
  import MessageBoxModal from '@/components/modals/message-box-modal/MessageBoxModal.vue';
  import AscxUserProfileEdit from '@rdss-mono/ascx-user-profile-edit';
  import { AccountRoles } from '@rdss-mono/ascx-types';

  const settingsModule = namespace('settings');

  @Component({
    components: {
      ASCXUsersList,
      MessageBoxModal,
      AscxUserProfileEdit
    }
  })
  export default class UsersDashboard extends Vue {
    readonly newPlus = require('./assets/images/new-plus.svg');
    private users = [];
    private asyncAction = false;
    private showEditModal = false;
    private showNewModal = false;
    private apiError = '';
    private language = supportedLanguages;
    private cachedUser = {
      userid: '',
      name: '',
      email_address: '',
      role: '',
      account_type: '',
      enable_mfa: '',
      using_temp_password: '',
      mobile_phone: '',
      language: ''
    } as any;
    private cachedEmail = '';
    private accountRoles = [AccountRoles.ADMIN, AccountRoles.MANAGER];

    private boxModalMessage = { message: '', status: '', buttonText: '' };
    @settingsModule.Action resendUserInvite;
    @settingsModule.Action resetUserPassword;
    @settingsModule.Action deleteUser;
    @settingsModule.Action editUser;
    @settingsModule.Action updateUser;
    @settingsModule.Action getUser;
    @Action changeLanguage;

    mounted(): void {
      this.getUsers();
    }

    public requestUserEdit({ email_address }: ACMEUserData): void {
      (async () => {
        const loading = this.$loading.show();
        try {
          const { data } = await this.getUser(email_address);
          this.updateCachedUser(data.user_info);
          loading.hide();
          this.showEditModal = true;
        } catch (error) {
          const message = getErrorMessage(error);
          loading.hide();
          this.updateMessageBox({
            status: 'label.failed',
            buttonText: 'button.continue',
            message
          });
        }
      })();
    }
    private openUserModal() {
      this.updateCachedUser({
        userid: '',
        name: '',
        email_address: '',
        role: 'admin',
        account_type: '',
        enable_mfa: '',
        using_temp_password: '',
        mobile_phone: '',
        language: 'en',
        account_primary_contact: false
      });
      this.showNewModal = true;
    }
    private updateCachedUser({
      userid,
      name,
      email_address,
      role,
      account_type,
      enable_mfa,
      mobile_phone,
      language,
      using_temp_password,
      account_primary_contact
    }): void {
      this.cachedUser.userid = userid;
      this.cachedUser.name = name;
      this.cachedUser.email_address = email_address;
      this.cachedUser.role = role.toLowerCase();
      this.cachedUser.account_type = account_type;
      this.cachedUser.enable_mfa = enable_mfa;
      this.cachedUser.mobile_phone = mobile_phone;
      this.cachedUser.language = language;
      this.cachedUser.using_temp_password = using_temp_password;
      this.cachedUser.account_primary_contact = account_primary_contact;

      this.cachedEmail = email_address;
    }

    /**
     * Updates user
     */
    private editUsr({ user, email }): void {
      this.asyncAction = true;
      this.updateUser({
        user,
        email
      })
        .then(() => {
          this.cancelEditModal();
          this.asyncAction = false;
          if (this.cachedEmail === this.$store.getters.user.email_address) {
            // Update current user data
            this.changeLanguage(user.language);
            // update current localstorage value of language
            const session = JSON.parse(localStorage.getItem('acme-session'));
            session.user.language = user.language;
            localStorage.setItem('acme-session', JSON.stringify(session));
            //
          }
          this.getUsers().catch((err) => {
            this.updateMessageBox({
              status: 'label.failed',
              message: getErrorMessage(err),
              buttonText: 'button.return'
            });
          });
        })
        .catch((error) => {
          this.asyncAction = false;
          this.apiError = this.resolveApiError(error);
        });
    }
    /**
     * Resend Invite
     */
    private async resendInvite(user): Promise<void> {
      this.cancelEditModal();
      const loader = this.$loading.show();
      try {
        await this.resendUserInvite(user.email);
        this.updateMessageBox({
          status: 'label.success',
          message: 'label.resend_invite_success',
          buttonText: 'button.continue'
        });
        loader.hide();
      } catch (error) {
        if (
          error.response.data.status_msg_key ===
          'rest_api.response.error.USER_ACCOUNT_NOT_FOUND'
        ) {
          this.updateMessageBox({
            status: 'label.user_not_found',
            message: 'errors.user_not_found',
            buttonText: 'button.close'
          });
        } else {
          this.updateMessageBox({
            status: 'label.failed',
            message: 'label.resend_invite_failed',
            buttonText: 'button.return'
          });
        }
        loader.hide();
      }
      this.$bvModal.show(this.messageBoxModalId);
    }
    /**
     * Reset Password
     */
    private async resetPassword(user): Promise<void> {
      this.cancelEditModal();
      const loader = this.$loading.show();
      try {
        await this.resetUserPassword(user.email);
        this.updateMessageBox({
          status: 'label.success',
          message: 'label.reset_password_success',
          buttonText: 'button.continue'
        });
        loader.hide();
      } catch (error) {
        const message = getErrorMessage(error);
        this.updateMessageBox({
          status: 'label.failed',
          buttonText: 'button.return',
          message
        });
        loader.hide();
      }
      this.$bvModal.show(this.messageBoxModalId);
    }
    /**
     * Create NewUser
     */
    public async requestNewUser(user: any): Promise<void> {
      this.resetUserError();
      this.$http
        .post('/user/new_user', user.user)
        .then(() => {
          this.cancelEditModal();
          this.getUsers().catch(this.handleGetUsersError);
        })
        .catch((err) => {
          this.apiError = this.resolveApiError(err);
        });
    }
    /**
     * Handle USER_ACCOUNT_IN_USE scenarios
     */
    private resolveApiError(err) {
      if (
        err.response.data.status_msg_key ===
        'rest_api.response.error.USER_ACCOUNT_IN_USE'
      ) {
        return `${this.$t('errors.user_email_address_exist')}`;
      }
      return `${this.$t(getErrorMessage(err))}`;
    }
    private deleteUsr({ email }) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('label.delete_user_warn')}`, {
          title: `${this.$t('label.delete_user')}`,
          size: 'lg',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: `${this.$t('texts.delete')}`,
          cancelTitle: `${this.$t('button.cancel')}`,
          footerClass:
            'p-2 delete-user-modal-footer justify-content-between form-buttons',
          bodyClass: 'text-center mt-5 mb-5 delete-user-modal',
          hideHeaderClose: true,
          centered: true
        })
        .then((accepted) => {
          if (!accepted) {
            return;
          }
          this.deleteUser(email)
            .then(() => {
              this.updateMessageBox({
                status: 'label.success',
                message: 'label.delete_user_success',
                buttonText: 'button.continue'
              });
              this.$bvModal.show(this.messageBoxModalId);
              this.getUsers().catch(this.handleGetUsersError);
            })
            .catch(() => {
              this.updateMessageBox({
                status: 'label.failed',
                message: 'label.delete_user_failed',
                buttonText: 'button.return'
              });
              this.$bvModal.show(this.messageBoxModalId);
            });
          this.cancelEditModal();
        });
    }

    private resetUserError(): void {
      this.apiError = '';
    }

    private isOwnActionDisabled(email): boolean {
      const { email_address } = this.$store.getters.user;
      return email === email_address;
    }
    public async getUsers(): Promise<void> {
      const loading = this.$loading.show();
      try {
        const response = await this.$http.get('/user');
        this.users = response.data.users_info.map((user) => {
          user.role = capitalizeSingleWord(user.role);
          return user;
        });

        loading.hide();
      } catch (err) {
        loading.hide();
      }
    }

    private updateMessageBox({ message, status, buttonText }): void {
      this.boxModalMessage.message = message;
      this.boxModalMessage.status = status;
      this.boxModalMessage.buttonText = buttonText;
    }

    private resetMessageBox(): void {
      this.$bvModal.hide(this.messageBoxModalId);
      this.cancelEditModal();
    }

    private get createNewUser(): string {
      return 'NewUserModal';
    }

    private get messageBoxModalId(): string {
      return 'messageBoxModal';
    }

    // ASCX Users List
    private translate(key: string): string {
      return this.$t(key).toString();
    }
    private cancelEditModal() {
      this.showEditModal = false;
      this.showNewModal = false;
      this.apiError = '';
    }
    private handleGetUsersError(error) {
      this.updateMessageBox({
        status: 'label.failed',
        message: getErrorMessage(error),
        buttonText: 'button.return'
      });
      this.$bvModal.show(this.messageBoxModalId);
    }
  }
  export interface UserData {
    userid: string;
    image_url?: string;
    name: string;
    email_address: string;
    role: string;
    account_type: string;
    enable_mfa: boolean;
  }

  export interface ACMEUserData {
    role: string;
    image_url?: string;
    name: string;
    mobile_phone: string;
    email_address: string;
    mfa_method: string;
    language: string;
  }
